<template>
  <van-pull-refresh
    v-model="isLoading"
    pulling-text="Tarik ke bawah untuk refresh..."
    loosing-text="Lepaskan untuk refresh..."
    success-text="Refresh Berhasil"
    @refresh="onRefresh"
  >
    <div class="loan-status">
      <BaseHeaderBar :showBackButton="true" />
      <div v-if="orderStatus === 1 || orderStatus === 2 || orderStatus === 10">
        <img class="top-img" src="@/assets/img/loan-status1.png" />
        <p class="intro">Dalam proses peninjauan</p>
      </div>

      <div v-else-if="orderStatus === 3" class="loan-status3">
        <img class="top-img" src="@/assets/img/loan-status3.png" />
        <p class="intro">Dalam proses pencairan</p>

        <p style="font-size: 16px; margin: 15px; color: #666">
          Jangan lupa untuk melakukan pelunasan ya! Pelunasan tepat waktu bisa
          meningkatkan limit tinggi dan menurunkan suku bunga
        </p>
        <div class="info">
          <div>
            <img class="icon" src="@/assets/img/loan-status3icon1.png" />
            <div class="desc">
              <p class="p1">Jumlah yang diterima</p>
              <p class="p2">Rp{{ loanInfo.ardrrivalAmount }}</p>
            </div>
          </div>
          <div>
            <img class="icon" src="@/assets/img/loan-status3icon2.png" />
            <div class="desc">
              <p class="p1">Nama bank penerima</p>
              <p class="p2">{{ loanInfo.ardrrivalBank }}</p>
            </div>
          </div>
          <div>
            <img class="icon" src="@/assets/img/loan-status3icon3.png" />
            <div class="desc">
              <p class="p1">Nomor rekening bank penerima</p>
              <p class="p2">{{ loanInfo.ardrrivalBankcard }}</p>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="orderStatus === 4" class="loan-status4">
        <img class="top-img" src="@/assets/img/loan-status4.png" />
        <p class="intro">Anda tidak dapat melakukan pinjaman saat ini</p>
        <p class="detail">
          Untuk saat ini Anda tidak memenuhi persyaratan. Pastikan untuk selalu
          mempertahankan riwayat kredit yang baik.
        </p>
        <!-- 贷超组件 -->
        <Daichao></Daichao>
      </div>

      <div v-else-if="orderStatus === 5" class="loan-status5">
        <div class="head">
          <div class="summary">
            <p class="p1">Menunggu pembayaran</p>
            <p class="p2">
              Harap melakukan pembayaran sebelum tanggal
              {{ loanInfo.bidrllDetails.redrpaymentTime }}
            </p>
          </div>
          <div class="detail" @click="goRepaymenDetail">Rincian</div>
        </div>
        <div class="repayment-record">
          <div class="overdue">
            Sudah terlambat {{ loanInfo.bidrllDetails.ovdrerdueDay }} hari
          </div>
          <div class="repayment-content">
            <div class="item">
              <p class="label">Pelunasan</p>
              <p class="amount">
                Rp{{ loanInfo.bidrllDetails.fudrllrepaymentAmount }}
              </p>
            </div>
            <div class="item">
              <p class="label">Perpanjangan</p>
              <p class="amount">
                Rp{{ loanInfo.bidrllDetails.exdrhibitionrepaymentaAount }}
              </p>
            </div>
          </div>
        </div>
        <p style="font-size: 16px; margin: 15px; color: #666">
          Jangan lupa untuk melakukan pelunasan ya! Pelunasan tepat waktu bisa
          meningkatkan limit tinggi dan menurunkan suku bunga
        </p>
        <button type="button" class="full-repayment-btn" @click="goRepay(1)">
          Pelunasan
        </button>
        <button
          type="button"
          class="installment-repayment-btn"
          @click="goRepay(2)"
        >
          Perpanjangan
        </button>
        <van-dialog v-model="visible" :showConfirmButton="false">
          <template #title>
            <p class="rise-limit-title">Suka "Pinjaman", nggak?</p>
          </template>
          <p class="tip">
            Beri ulasan bintang 5, Anda akan mendapatkan benefit peningkatan
            limit, pengurangan bunga, dan pencairan cepat.
          </p>
          <button type="button" class="confirm-btn" @click="toStore()">
            Beri nilai
          </button>
          <img
            class="hiddenIcon"
            src="@/assets/img/hiddenIcon.png"
            @click="toStore(false)"
          />
        </van-dialog>
      </div>

      <div
        v-else-if="orderStatus === 0 || orderStatus === 6 || orderStatus === 7"
        class="loan-status6"
      >
        <div class="home-top-bg"></div>
        <div class="loan-amount">
          <img src="@/assets/img/loan-amount.png" alt="Jumlah Pinjaman" />
          <div class="desc">
            <div class="amount">Rp{{ loanAmount }}</div>
            <p>Jumlah Pinjaman</p>
          </div>
        </div>
        <div class="loan-amount-sort">
          <div
            v-for="(item, index) in loanInfo.mudrltipleLoanList"
            :key="index"
          >
            <div class="circle-block">
              <div
                :class="[
                  'circle',
                  item.loanStatus ? 'circle1' : 'circle2',
                  { active: activeIndex === index },
                ]"
                @click="selectLoan(item, index)"
              ></div>
            </div>
            <div class="label">Rp{{ item.loanMoney }}</div>
          </div>
        </div>
        <div class="deadline" v-if="loanTimers && loanTimers.length">
          <img
            class="alarm-icon"
            src="@/assets/img/home-alarm.png"
            alt="alarm"
          />
          <div class="time">
            <p class="p1">Lama Pinjaman</p>
            <p class="p2">{{ loanTimers[0] }} Hari</p>
          </div>
        </div>
        <div
          class="deadline"
          v-if="
            orderStatus === 0 || (orderStatus === 7 && loanInfo.redr_loan === 0)
          "
        >
          <img class="rate-icon" src="@/assets/img/rate-icon.png" />
          <div class="time">
            <p class="p1">Suku bunga tahunan</p>
            <p class="p2">{{ loanInfo.dadryRate }}</p>
          </div>
        </div>
        <button type="button" class="submit" @click="submit">
          Langsung Pengajuan
        </button>

        <van-dialog
          v-model="visible"
          :showConfirmButton="false"
          close-on-click-overlay
        >
          <template #title>
            <p class="rise-limit-title">Saya ingin menaikkan limit</p>
          </template>
          <h3 class="tip-title">Bagaimana cara menaikkan limit pinjaman</h3>
          <p class="tip">
            Mempertahankan kebiasaan baik dan selalu melunasi tepat waktu. Anda
            bisa mendapatkan kenaikan limit apabila menghindari perilaku
            keterlambatan pembayaran.
          </p>
          <button type="button" class="confirm-btn" @click="onCloseDialog()">
            Mengerti
          </button>
        </van-dialog>
      </div>

      <div v-else-if="orderStatus === 8" class="loan-status8">
        <img class="top-img" src="@/assets/img/loan-status8.png" />
        <p class="intro">Verifikasi KTP gagal</p>
        <p class="detail">
          Foto KTP yang Anda unggah tidak jelas, sehingga tidak dapat lolos
          evaluasi. Silakan unggah ulang.
        </p>
        <button type="button" class="modify-btn" @click="goAuthentication">
          Edit data
        </button>
      </div>

      <div v-else-if="orderStatus === 9" class="loan-status9">
        <img class="top-img" src="@/assets/img/loan-status9.png" />
        <p class="intro">Pencairan gagal</p>
        <p class="detail">
          Pencairan tidak dapat dilakukan karena data rekening bank Anda tidak
          tersedia. Silakan isi ulang.
        </p>
        <button type="button" class="modify-btn" @click="goBankInfo">
          Edit data
        </button>
        <van-dialog
          v-model="cancelLoanVisible"
          :showConfirmButton="false"
          close-on-click-overlay
          class="cancel-loan-dialog"
        >
          <div class="cancel-loan-dialog-bg"></div>
          <h3 class="cancel-loan-dialog-title">Berhasil dibatalkan</h3>
          <p class="tip">
            Pinjaman Anda telah dibatalkan. Anda tidak perlu verifikasi data
            lagi jika ingin melakukan pinjaman.
          </p>
          <button
            type="button"
            class="confirm-btn"
            @click="onCloseDialog('cancelLoanVisible')"
          >
            Mengerti
          </button>
        </van-dialog>
      </div>

      <div v-else-if="orderStatus === 12" class="loan-status12">
        <div class="home-top-bg"></div>
        <div class="loan-amount">
          <img src="@/assets/img/loan-amount.png" alt="Jumlah Pinjaman" />
          <div class="desc">
            <div class="amount">Rp{{ loanInfo.lodranMaxAmount }}</div>
            <p>Jumlah Pinjaman</p>
          </div>
        </div>
        <div class="loan-amount-sort" :style="{ justifyContent: 'center' }">
          <div>
            <div class="circle-block">
              <div class="circle circle1 active"></div>
            </div>
            <div class="label">Rp{{ loanInfo.lodranAmount }}</div>
          </div>
        </div>
        <div
          class="deadline"
          v-if="loanInfo.avdrailablePeriod && loanInfo.avdrailablePeriod.length"
        >
          <img
            class="alarm-icon"
            src="@/assets/img/home-alarm.png"
            alt="alarm"
          />
          <div class="time">
            <p class="p1">Lama Pinjaman</p>
            <p class="p2">{{ loanInfo.avdrailablePeriod[0] }} Hari</p>
          </div>
        </div>
        <button type="button" class="withdraw-btn" @click="withdraw">
          Segera Tarik
        </button>
      </div>

      <van-dialog
        v-model="feeVisible"
        :showConfirmButton="false"
        close-on-click-overlay
      >
        <template #title>
          <p class="fee-title">Rincian Biaya</p>
        </template>
        <div class="fee-detail">
          <div>
            <p>Jumlah Pinjaman</p>
            <p>Rp{{ feeDetail.lodranCash }}</p>
          </div>
          <div>
            <p>Jumlah hari pinjaman</p>
            <p>{{ feeDetail.lodranTimes }} Hari</p>
          </div>
          <div>
            <p>Biaya Administrasi</p>
            <p>Rp{{ feeDetail.todrtalCost }}</p>
          </div>
          <div>
            <p>Jumlah yang diterima</p>
            <p>Rp{{ feeDetail.ardrrivalCash }}</p>
          </div>
          <div v-if="orderStatus === 6">
            <p>Penurunan suku bunga</p>
            <p style="color: red">{{ feeDetail.lodrwerInterestrate }}</p>
          </div>
        </div>
        <div class="footer-btn">
          <button
            type="button"
            class="cancel-btn"
            @click="onCloseDialog('feeVisible')"
          >
            Batal
          </button>
          <button type="button" class="ok-btn" @click="onOkFee">Yakin</button>
        </div>
      </van-dialog>
    </div>

    <template #loading>
      <span></span>
    </template>
  </van-pull-refresh>
</template>

<script>
import Vue from 'vue'
import { Dialog, Toast, PullRefresh } from 'vant'
import request from '@/utils/request'
import dialogVisible from '@/mixins/dialogVisible'
import { getMetadata } from '@/utils/getEquipmentInfo.js'

Vue.use(Toast)
Vue.use(PullRefresh)

export default {
  name: 'Home',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      isLoading: false,
      cancelLoanVisible: false,
      feeVisible: false,
      orderStatus: '',
      loanInfo: {},
      feeDetail: {},
      activeIndex: -1,
      loanTimers: [],
      loanAmount: ''
    }
  },
  created () {
    this.getOrderStatus()
  },
  methods: {
    getOrderStatus (isRefresh) {
      request.post('homeDRInterface')
        .then(res => {
          this.orderStatus = res.ordrderStatus
          this.loanInfo = res
          // 去好评弹窗
          if (res.ordrderStatus === 5) {
            const showEvaluate = localStorage.getItem('showEvaluate')
            if (showEvaluate !== 'true') {
              this.visible = true
            }
          }
          if (res.ordrderStatus === 0 || res.ordrderStatus === 6 || res.ordrderStatus === 7) {
            let index = -1
            res.mudrltipleLoanList.forEach((item, i) => {
              if (item.loanStatus) {
                index = i
              }
            })
            this.activeIndex = index
            this.loanAmount = res.mudrltipleLoanList[index].loanMoney
            this.selectedLoanItem = res.mudrltipleLoanList[index]
            this.loanTimers = this.initLoanTimers = (res.mudrltipleLoanList && res.mudrltipleLoanList[0] && res.mudrltipleLoanList[0].loanTimers) || []
          }
          isRefresh && (this.isLoading = false)
        })
    },
    getFeeDetail (item, loanType) {
      const postData = {
        lodranCash: item.loanMoney,
        lodranTimers: item.loanTimers[0],
        lodranType: loanType
      }
      this.withdrawType = loanType
      request.post('indrterestrateBomb', postData)
        .then(res => {
          this.feeDetail = res
        })
    },
    goRepaymenDetail () {
      this.$router.push('/repaymenDetail')
    },
    goBankInfo () {
      if (this.loanInfo.badrnkStatus) {
        const postData = {
          apdrplicationAmount: this.loanInfo.lodranAmount,
          prdromotionChannel: 'saku'
        }
        // 获取设备信息
        getMetadata()
          .then(res => {
            // 提交设备信息
            return request.post('deviceInformation', res)
          })
          .then(() => {
            // 提交订单
            return request.post('sudrbmitloanOrder', postData)
          })
          .then(() => {
            this.getOrderStatus()
          })
      } else {
        this.goAuthentication()
      }
    },
    goRepay (repayType) {
      const { ordrderID, padrymentID, fudrllrepaymentAmount, exdrhibitionrepaymentaAount } = this.loanInfo.bidrllDetails
      this.$router.push({
        path: '/repay',
        query: {
          repayType,
          amount: repayType === 1 ? fudrllrepaymentAmount : exdrhibitionrepaymentaAount,
          orderId: ordrderID,
          payID: padrymentID
        }
      })
    },
    selectLoan (item, index) {
      const loanStatus = item.loanStatus
      if (loanStatus) {
        if (index !== this.activeIndex) {
          this.activeIndex = index
          this.selectedLoanItem = item
          this.loanAmount = item.loanMoney
          this.loanTimers = item.loanTimers
        }
      } else {
        this.visible = true
      }
    },
    submit () {
      if (this.selectedLoanItem) {
        if (this.orderStatus === 0 || (this.orderStatus === 7 && this.loanInfo.redr_loan === 0)) {
          this.$router.push({
            path: '/authentication',
            query: {
              amount: this.selectedLoanItem.loanMoney
            }
          })
        } else if (this.orderStatus === 6 || (this.orderStatus === 7 && this.loanInfo.redr_loan === 1)) {
          this.getFeeDetail(this.selectedLoanItem, 2)
          this.feeVisible = true
        }
      } else {
        Toast('Silahkan pilih pinjaman terlebih dahulu')
      }
    },
    onOkFee () {
      if (this.flag) return
      this.flag = true
      const { lodranCash, lodranTimes } = this.feeDetail
      const postData = {
        lodranAmount: lodranCash,
        lodranPeriod: lodranTimes,
        widrthdrawType: this.withdrawType
      }
      request.post('widrthdrawCash', postData)
        .then(() => {
          this.feeVisible = false
          this.orderStatus = 1
        })
        .finally(() => {
          this.flag = false
        })
    },
    goAuthentication () {
      this.$router.push({
        path: '/authentication',
        query: {
          amount: this.loanInfo.lodranAmount
        }
      })
    },
    withdraw () {
      const postData = {
        loanMoney: this.loanInfo.lodranAmount,
        loanTimers: this.loanInfo.avdrailablePeriod
      }
      this.getFeeDetail(postData, 1)
      this.feeVisible = true
    },
    onRefresh () {
      this.getOrderStatus(true)
    },
    // 跳转应用商店
    toStore (isToStore = true) {
      if (isToStore) {
        const apitype = localStorage.getItem('apitype') || '1'
        if (apitype === '1') {
          window.location = 'https://apps.apple.com/id/app/akuyuk-pinjaman-hitung/id6476659450?l=id'
        }
        if (apitype === '2') {
          window.location = 'https://apps.apple.com/id/app/akuku-hitung-pinjaman-cepat/id6479757791?l=id'
        }
      }
      this.visible = false
      localStorage.setItem('showEvaluate', true)
    }
  }
}
</script>

<style scoped lang="less">
.top-img {
  width: 100%;
}

.intro {
  line-height: 42px;
  margin-top: 24px;
  font-size: 30px;
  color: #302c48;
  text-align: center;
}

.home-top-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding-top: 88.4%;
  background: url(../assets/img/home-top-bg.png) no-repeat;
  background-size: cover;
}

.loan-amount {
  position: relative;
  width: 462px;
  height: 462px;
  margin: 43px auto 0;

  img {
    width: 100%;
    height: 100%;
  }

  .desc {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    color: #fff;
    text-align: center;

    .amount {
      font-size: 60px;
    }

    p {
      margin-top: 20px;
      font-size: 32px;
    }
  }
}

.loan-amount-sort {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 17px 84px 0;

  & > div {
    width: 174px;

    &:not(:nth-child(3n + 1)) {
      margin-left: 30px;
    }

    .circle-block {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 130px;
    }

    .circle1 {
      width: 40px;
      height: 40px;
      border: 2px solid #d6dbe7;
      border-radius: 50%;
      background-color: #e83829;

      &.active {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 130px;
        height: 130px;
        border: 2px solid #e83829;
        border-radius: 50%;
        background-color: #fff;

        &::before {
          content: "";
          width: 36px;
          height: 36px;
          border-radius: 50%;
          background-color: #e83829;
        }
      }
    }

    .circle2 {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #d6dbe7;
    }

    .label {
      height: 42px;
      line-height: 42px;
      margin-top: 24px;
      font-size: 30px;
      font-weight: 600;
      color: #000;
      text-align: center;
    }
  }
}

.deadline {
  display: flex;
  margin-left: 84px;
  margin-right: 84px;

  .alarm-icon {
    width: 117px;
    height: 113px;
    margin-top: 24px;
  }

  .rate-icon {
    width: 117px;
    height: 130px;
    margin-top: 35px;
    margin-bottom: 59px;
  }

  .time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 14px;
  }

  .p1 {
    height: 42px;
    line-height: 42px;
    font-size: 30px;
    font-weight: 500;
    color: #333;
  }

  .p2 {
    height: 33px;
    line-height: 33px;
    margin-top: 24px;
    font-size: 24px;
    color: #909aab;
  }
}

.loan-status {
  min-height: 100vh;
  background-color: @body-bg-color;
}

.loan-status3 {
  .info {
    margin-top: 24px;
    margin-left: 40px;

    & > div {
      display: flex;

      &:nth-child(n + 2) {
        margin-top: 22px;
      }

      .icon {
        width: 180px;
        height: 154px;
      }

      .desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 30px;
      }

      .p1 {
        height: 42px;
        line-height: 42px;
        font-size: 30px;
        font-weight: 500;
        color: #333;
      }

      .p2 {
        height: 30px;
        line-height: 30px;
        margin-top: 24px;
        font-size: 22px;
        color: #909aab;
      }
    }
  }
}

.loan-status4 {
  .detail {
    margin-top: 24px;
    font-size: 24px;
    color: #302c48;
    text-align: center;
  }
}

.loan-status5 {
  padding-left: 23px;
  padding-right: 23px;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .summary {
    .p1 {
      font-size: 44px;
      font-weight: 500;
      color: #3a3a3b;
    }

    .p2 {
      margin-top: 10px;
      font-size: 26px;
      color: #727272;
    }
  }

  .detail {
    width: 184px;
    height: 60px;
    line-height: 60px;
    border-radius: 30px;
    font-size: 26px;
    font-weight: 500;
    color: #4e6cff;
    text-align: center;
    background-color: #e0e9ff;
  }

  .repayment-record {
    margin-top: 41px;
    border-radius: 20px;
    box-shadow: inset 1px 1px 0px 1px rgba(255, 255, 255, 0.5);
    background-image: linear-gradient(360deg, #a9b8e7 0%, #2952e5 100%);
    overflow: hidden;
  }

  .overdue {
    float: right;
    width: 343px;
    height: 50px;
    line-height: 50px;
    margin-top: 20px;
    margin-bottom: 17px;
    background-color: rgba(178, 191, 255, 0.48);
    border-radius: 100px 0px 0px 100px;
    font-size: 22px;
    color: #fff;
    text-align: center;
  }

  .repayment-content {
    clear: both;
    padding: 22px 30px;
    margin: 0 20px 21px;
    border-radius: 10px;
    background-color: #fff;

    .item {
      padding: 14px 0 14px 31px;
      border: 2px solid #e4e9f0;
      border-radius: 10px;

      &:nth-child(n + 2) {
        margin-top: 16px;
      }
    }

    .label {
      font-size: 24px;
      color: #43464a;
    }

    .amount {
      margin-top: 4px;
      font-size: 26px;
      color: #fe774c;
    }
  }

  .full-repayment-btn {
    .submit-btn;
    margin-top: 41px;
  }

  .installment-repayment-btn {
    .submit-btn;
    margin-top: 5px;
    color: #f9f9f9;
    background-image: url(../assets/img/installment-repayment-btn.png);
  }
  .rise-limit-title {
    .dialog-title;
    padding-left: 24px;
    text-align: left;
  }

  .tip {
    padding-left: 49px;
    padding-right: 49px;
    margin-top: 16px;
    font-size: 26px;
    color: #999;
    text-align: center;
  }

  .confirm-btn {
    width: 634px;
    height: 88px;
    line-height: 88px;
    border-radius: 8px;
    margin: 80px auto 84px;
    font-size: 30px;
    color: #fff;
    background-color: @theme-color;
  }
  .hiddenIcon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.loan-status6 {
  position: relative;
  overflow: auto;
  padding-bottom: 200px;

  .submit {
    .submit-btn;
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }

  .rise-limit-title {
    .dialog-title;
    padding-left: 24px;
    text-align: left;
  }

  .tip-title {
    margin-top: 32px;
    font-size: 30px;
    color: #333;
    text-align: center;
  }

  .tip {
    padding-left: 49px;
    padding-right: 49px;
    margin-top: 16px;
    font-size: 26px;
    color: #999;
    text-align: center;
  }

  .confirm-btn {
    width: 634px;
    height: 88px;
    line-height: 88px;
    border-radius: 8px;
    margin: 80px auto 84px;
    font-size: 30px;
    color: #fff;
    background-color: @theme-color;
  }
}

.loan-status8 {
  .detail {
    margin: 24px 78px 0;
    font-size: 24px;
    color: #999;
    text-align: center;
  }

  .modify-btn {
    .submit-btn;
    width: 690px;
    margin-top: 108px;
  }
}

.loan-status9 {
  .intro {
    margin-top: 153px;
  }
  .detail {
    margin-top: 24px;
    font-size: 24px;
    color: #302c48;
    text-align: center;
  }

  .modify-btn {
    .submit-btn;
    margin-top: 108px;
  }

  .cancel-loan-dialog {
    width: 586px;
  }

  .dialog-title {
    .dialog-title;
    line-height: 124px;
  }

  .tip {
    margin-top: 58px;
    font-size: 24px;
    color: #302c48;
    text-align: center;
  }

  .confirm-btn {
    width: 516px;
    height: 187px;
    line-height: 187px;
    margin: 68px auto 28px;
    font-size: 30px;
    color: #fff;
    background: url(../assets/img/dialog-confirm-btn.png) no-repeat;
    background-size: cover;
  }
}

.loan-status12 {
  padding: 12px 30px 0;

  .withdraw-btn {
    .submit-btn;
    width: 100%;
    margin-top: 80px;
    margin-bottom: 98px;
  }
}

.fee-title {
  .dialog-title;
}

.fee-detail {
  margin: 32px 25px 197px;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 88px;
    line-height: 88px;
    padding-left: 24px;
    padding-right: 24px;

    &:nth-child(n + 2) {
      margin-top: 6px;
    }

    &:nth-child(2),
    &:nth-child(4) {
      background-color: #f4f5f6;
      border-radius: 8px;
    }

    p {
      font-size: 26px;
      color: #333;
    }
  }
}

.footer-btn {
  display: flex;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 45px;

  button {
    flex: 1;
    height: 88px;
    line-height: 88px;
    font-size: 30px;
    color: #fff;
    border-radius: 8px;
  }

  .cancel-btn {
    background-color: #d9dade;
  }

  .ok-btn {
    margin-left: 20px;
    background-color: @theme-color;
  }
}
</style>
